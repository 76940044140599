import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";

// Custom Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PageTitle from "examples/NewDesign/PageTitle";
import PartnerData from "layouts/partner/data/PartnerData";
import DataTable from "examples/Tables/DataTable";
import SearchMenu from "examples/multiSearchBar/MultiSearchBar";
import ResetFilterButton from "components/Buttons/ResetButton";
import BasicModal from "examples/modal/BasicModal/BasicModal";
import CustomButton from "examples/NewDesign/CustomButton";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";

// Constant
import Constants, {
  defaultData,
  PageTitles,
  ModalContent,
  ButtonTitles,
  Colors,
  Icons,
} from "utils/Constants";

// Redux component
import { openSnackbar } from "redux/Slice/Notification";
import { useDispatch } from "react-redux";
import { getAllPartners } from "redux/Thunks/MobileUser";
import CustomSearch from "components/Search/Search";
import pxToRem from "assets/theme/functions/pxToRem";
import FTextField from "components/Form/FTextField";
import { activeateUserThunk, registerWebThunk } from "redux/Thunks/UserManagement";
import { Divider } from "@mui/material";

const initialPartnerState = {
  open: false,
  body: {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    role: "partner",
    partnerCode: "",
  },
  error: {},
};

function feedbacks() {
  const [filters, setFilters] = useState([
    {
      inputLabel: "Search By First Name",
      list: [],
      selectedValue: "",
      isLoading: false,
      title: "First Name",
      checked: true,
      id: "firstName",
    },
    {
      inputLabel: "Search By Last Name",
      list: [],
      selectedValue: "",
      isLoading: false,
      title: "Last Name",
      checked: false,
      id: "lastName",
    },
    {
      inputLabel: "Search By Mobile Number",
      list: [],
      selectedValue: "",
      isLoading: false,
      title: "Mobile Number",
      checked: false,
      id: "mobileNumber",
    },
  ]);
  const [partner, setPartner] = useState(initialPartnerState);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(Constants.PENDING);
  const [loadingForm, setLoadingForm] = useState(false);
  const [tablePagination, setTablePagination] = useState({
    page: 0,
    perPage: defaultData.DATE_ON_SINGLE_API_CALL,
  });
  const [next, setNext] = useState(0);
  const dispatch = useDispatch();
  let debounceTimeout;

  const handleFilter = async (temp = filters) => {
    setLoading(Constants.PENDING);
    setTablePagination({ ...tablePagination, page: 0 });
    setNext(0);
    const paramData = {
      page: 0,
      perPage: tablePagination.perPage,
      ...(temp[0].selectedValue !== "" && { firstName: temp[0].selectedValue }),
      ...(temp[1].selectedValue !== "" && { lastName: temp[1].selectedValue }),
      ...(temp[2].selectedValue !== "" && {
        mobileNumber: temp[2].selectedValue,
      }),
    };
    const data = new URLSearchParams(paramData);
    const res = await dispatch(getAllPartners(data));
    const firstNameList = [];
    const lastNameList = [];
    const mobileNumberList = [];

    if (res.payload.status === 200) {
      res.payload.data.data.forEach((item) => {
        const { firstName, lastName, mobileNumber } = item;
        if (firstName && firstName !== "") {
          firstNameList.push({ [Constants.MONGOOSE_ID]: firstName, title: firstName });
        }
        if (lastName && lastName !== "") {
          lastNameList.push({ [Constants.MONGOOSE_ID]: lastName, title: lastName });
        }

        if (mobileNumber && mobileNumber !== "") {
          mobileNumberList.push({ [Constants.MONGOOSE_ID]: mobileNumber, title: mobileNumber });
        }
      });
      setList(res.payload.data.data);
      setLoading(Constants.FULFILLED);
    } else {
      dispatch(
        openSnackbar({
          message: Constants.MOBILE_USERS_ERROR,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  const handleActivateUser = async (id, isActive) => {
    const res = await dispatch(activeateUserThunk({ id, body: { isActive } }));
    if (res.payload.status === 200) {
      const userIndex = list.findIndex((user) => user[Constants.MONGOOSE_ID] === id);
      const tempUserList = [...list];
      tempUserList[userIndex].isActive = isActive;
      setList(tempUserList);
      dispatch(
        openSnackbar({
          message: isActive ? Constants.USER_ACTIVATED_SUCCESS : Constants.USER_DEACTIVATED_SUCCESS,
          notificationType: "success",
        })
      );
    } else {
      dispatch(
        openSnackbar({
          message: Constants.MOBILE_USERS_ERROR,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  const { columns, rows } = PartnerData(list, handleActivateUser);

  const handleTablePagination = async () => {
    const data = new URLSearchParams({
      page: next + 1,
      perPage: tablePagination.perPage,
    });
    const res = await dispatch(getAllPartners(data));
    if (res.payload.status === 200) {
      setList([...list, ...res.payload.data.data]);
      setNext(res.payload.data.data.length > 0 ? next + 1 : next);
    }
  };

  useEffect(() => {
    (async () => {
      handleFilter();
    })();
  }, []);

  const handleReload = async () => {
    setLoading(Constants.PENDING);
    handleFilter();
  };

  const debouncedHandleSearch = (e, i) => {
    const temp = [...filters];
    temp[i].list = [{ [Constants.MONGOOSE_ID]: e.target.value, title: e.target.value }];
    temp[i].selectedValue = e.target.value;
    setFilters(temp);
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(async () => handleFilter(temp), 500);
  };

  const handleResetFilter = () => {
    const tempFilters = [...filters];
    tempFilters[0].selectedValue = "";
    tempFilters[1].selectedValue = "";
    tempFilters[2].selectedValue = "";
    setFilters(tempFilters);
    handleFilter(tempFilters);
  };

  const handleClose = () => setPartner(initialPartnerState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPartner((prev) => ({ ...prev, body: { ...prev.body, [name]: value } }));
  };

  const formValidation = () => {
    const { firstName, lastName, email, password, confirmPassword, partnerCode } = partner.body;

    const error = {};
    if (firstName === "") error.firstName = "Required";
    if (lastName === "") error.lastName = "Required";
    if (email === "") error.email = "Required";
    if (password === "") error.password = "Required";
    if (confirmPassword === "") error.confirmPassword = "Required";
    if (password !== confirmPassword) error.confirmPassword = "Password does not match";
    if (partnerCode === "") error.partnerCode = "Required";
    setPartner((prev) => ({ ...prev, error }));
    return Object.keys(error).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = formValidation();
    if (!isValid) return;
    setLoadingForm(true);
    const res = await dispatch(registerWebThunk(partner.body));
    if (res.payload.status === 200) {
      dispatch(
        openSnackbar({
          message: Constants.PARTNER_CREATED_SUCCESS,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
      setPartner(initialPartnerState);
      handleFilter();
    } else {
      dispatch(
        openSnackbar({
          message: res.payload.data.message,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
    setLoadingForm(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox display="flex" justifyContent="space-between">
        <PageTitle title={PageTitles.MOBILE_USERS} />
        <MDBox display="flex" flexDirection="row">
          <CustomButton
            title={ButtonTitles.PARTNER}
            icon="add_circle_outline"
            background={Colors.PRIMARY}
            color={Colors.WHITE}
            openModal={() => setPartner((prev) => ({ ...prev, open: true }))}
          />
          <Divider
            orientation="vertical"
            sx={{
              backgroundColor: "var(--gray-300, #D0D5DD)",
              height: "auto",
              marginLeft: pxToRem(16),
              marginRight: 0,
            }}
          />
          <BasicButton
            icon={Icons.RELOAD}
            background={Colors.WHITE}
            border
            color={Colors.BLACK}
            action={handleReload}
          />
        </MDBox>
      </MDBox>

      <MDBox
        display="flex"
        alignItems="start"
        flexWrap="wrap"
        sx={{ flexDirection: "row", mr: 2, mb: 1 }}
        style={{ width: "100%" }}
      >
        {/* search */}
        {filters
          .filter((filter) => filter.checked)
          .map((filter, i) => (
            <CustomSearch
              isLoading={filter.isLoading}
              options={filter.list}
              value={filter.selectedValue}
              debouncedHandleSearch={(e) => debouncedHandleSearch(e, i)}
              placeholder={`Search ${filter.title}`}
              label={filter.title}
              width={pxToRem(300)}
              marginRight={pxToRem(10)}
              freeSolos
            />
          ))}
        <ResetFilterButton handleReset={handleResetFilter} />
        <SearchMenu searchOptions={filters} setSearchOptions={setFilters} />
      </MDBox>

      <MDBox mt={2}>
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
          showTotalEntries={false}
          noEndBorder
          loading={loading}
          currentPage={tablePagination.page}
          handleTablePagination={handleTablePagination}
          handleCurrentPage={(page) => setTablePagination({ ...tablePagination, page })}
        />
      </MDBox>

      {/*  Partner Registration */}
      <BasicModal
        open={partner.open}
        handleClose={handleClose}
        title={ModalContent.NEW_PARTNER}
        actionButton={
          (!loadingForm && ButtonTitles.SUBMIT) || (loadingForm && ButtonTitles.SUBMIT_LOADING)
        }
        handleAction={handleSubmit}
      >
        <FTextField
          label="First Name*"
          placeholder="Enter First Name"
          id="firstName"
          name="firstName"
          type="text"
          error={Boolean(partner?.error?.firstName)}
          helperText={partner?.error?.firstName}
          value={partner.body.firstName}
          handleChange={handleChange}
          marginBottom={1}
        />
        <FTextField
          label="Last Name*"
          placeholder="Enter Last Name"
          id="lastName"
          name="lastName"
          type="text"
          error={Boolean(partner?.error?.lastName)}
          helperText={partner?.error?.lastName}
          value={partner.body.lastName}
          handleChange={handleChange}
          marginBottom={1}
        />
        <FTextField
          label="Email*"
          placeholder="Enter Email"
          id="email"
          name="email"
          type="email"
          error={Boolean(partner?.error?.email)}
          helperText={partner?.error?.email}
          value={partner.body.email}
          handleChange={handleChange}
          marginBottom={1}
        />
        <FTextField
          label="Password*"
          placeholder="Enter Password"
          id="password"
          name="password"
          type="password"
          error={Boolean(partner?.error?.password)}
          helperText={partner?.error?.password}
          value={partner.body.password}
          handleChange={handleChange}
          marginBottom={1}
        />
        <FTextField
          label="Confirm Password*"
          placeholder="Enter Confirm Password"
          id="confirmPassword"
          name="confirmPassword"
          type="password"
          error={Boolean(partner?.error?.confirmPassword)}
          helperText={partner?.error?.confirmPassword}
          value={partner.body.confirmPassword}
          handleChange={handleChange}
          marginBottom={1}
        />
        <FTextField
          label="Partner Code*"
          placeholder="Enter Partner Code"
          id="partnerCode"
          name="partnerCode"
          type="text"
          error={Boolean(partner?.error?.partnerCode)}
          helperText={partner?.error?.partnerCode}
          value={partner.body.partnerCode}
          handleChange={handleChange}
          marginBottom={1}
        />
      </BasicModal>
    </DashboardLayout>
  );
}

export default feedbacks;
