import { createAsyncThunk } from "@reduxjs/toolkit";
import Sessions from "utils/Sessions";
import ApiService from "../ApiService/ApiService";

const UserListThunk = createAsyncThunk("userlist/api", async (param) => {
  const res = await ApiService.get(`users${param ? `?${param}` : ""}`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  })
    .then((r) => r)
    .catch((err) => err.response);
  return res;
});

export const myProfileThunk = createAsyncThunk("my-profile/api", async () => {
  const res = await ApiService.get(`/user/profile`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  })
    .then((r) => r)
    .catch((err) => err.response);
  return res;
});

export const UserListbyIdThunk = createAsyncThunk("userlist/api", async (id) => {
  const res = await ApiService.get(`users/${id}`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  })
    .then((r) => r)
    .catch((err) => err.response);
  return res;
});

export const activeateUserThunk = createAsyncThunk("activate/api", async (data) => {
  const res = await ApiService.patch(
    `user/activate/${data.id}`,
    { ...data.body },
    {
      headers: { Authorization: `Bearer ${Sessions.userToken}` },
    }
  );
  return res;
});

export const registerWebThunk = createAsyncThunk("web/register", async (body) => {
  const res = await ApiService.post(
    `admin/register`,
    { ...body },
    {
      headers: { Authorization: `Bearer ${Sessions.userToken}` },
    }
  )
    .then((r) => r)
    .catch((err) => err.response);
  return res;
});

export default UserListThunk;
