// Admin layouts
import SignIn from "layouts/authentication/sign-in";
import CustomerLogin from "layouts/authentication/sign-in/customerLogin";
import ResetPassword from "layouts/authentication/reset-password";
import ForgotPassword from "layouts/authentication/forgot-password";
import Profile from "layouts/userprofile";
import LeadBay from "layouts/loanForm";
import FormScreen from "layouts/loanForm/formScreen";
import Bill from "layouts/billPayments";
import LoanForm from "layouts/mobileProfile/LoanForm";
import MobileUsers from "layouts/mobileUsers";
import PartnerUsers from "layouts/partner/Partner";
import Home from "layouts/home/home";

// Upcoming Layouts
import BankAccountOpening from "layouts/bankOpening/BankOpening";

// Super Admin Layouts
import LeadUtilsBanks from "layouts/leadUtils/bank/Bank";
import LeadUtilsCodes from "layouts/leadUtils/codes/Code";
import LeadUtilsSalesManager from "layouts/leadUtils/salesManager/SalesManager";

// @mui icons
import Icon from "@mui/material/Icon";

export const authenticationRoute = [
  {
    type: "page",
    name: "Sign In",
    key: "sign-in",
    license: [],
    permissions: [],
    icon: <Icon fontSize="small">SignIn</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "page",
    name: "Sign In",
    key: "sign-in",
    license: [],
    permissions: [],
    icon: <Icon fontSize="small">SignIn</Icon>,
    route: "/sign-in",
    component: <CustomerLogin />,
  },
  {
    type: "page",
    name: "Forgot Password",
    key: "forgot-password",
    license: [],
    permissions: [],
    icon: <Icon fontSize="small">Forgot Password</Icon>,
    route: "/authentication/forgot-password",
    component: <ForgotPassword />,
  },
  {
    type: "page",
    name: "Reset Password",
    key: "reset-password",
    license: [],
    permissions: [],
    icon: <Icon fontSize="small">Reset Password</Icon>,
    route: "/authentication/reset-password/:id/:token",
    component: <ResetPassword />,
  },
];

const adminRoutes = [
  {
    type: "collapse",
    name: "Home",
    key: "client",
    parent: "",
    license: [],
    permissions: [],
    icon: <Icon fontSize="small">home</Icon>,
    route: "/client",
    component: <Home />,
  },
  {
    type: "collapse",
    name: "Lead Bay",
    key: "client/lead-bay-home",
    parent: "",
    license: [],
    permissions: [],
    icon: <Icon fontSize="small">credit_score</Icon>,
    route: "/client/lead-bay",
    component: <LeadBay />,
  },
  {
    type: "collapse",
    name: "Loans & Credit Cards",
    key: "client/lead-bay",
    parent: "Lead Bay",
    license: [],
    permissions: [],
    icon: <Icon fontSize="small">credit_score</Icon>,
    route: "/client/lead-bay",
    component: <LeadBay />,
  },

  {
    type: "collapse",
    name: "Bank Account Opening",
    key: "client/bank-account-opening",
    parent: "Lead Bay",
    license: [],
    permissions: [],
    icon: <Icon fontSize="small">person</Icon>,
    route: "/client/bank-account-opening",
    component: <BankAccountOpening />,
  },
  {
    type: "collapse",
    name: "Insurance",
    key: "client/insurance",
    parent: "Lead Bay",
    license: [],
    permissions: [],
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "",
    href: "http://www.indusappstore.com/details/indus/1055207",
  },
  {
    type: "collapse",
    name: "Utility Bill Payments",
    key: "client/bill",
    parent: "Lead Bay",
    license: [],
    permissions: [],
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/client/bill",
    component: <Bill />,
  },
  {
    type: "collapse",
    name: "About Us",
    key: "client/about-us",
    parent: "",
    license: [],
    permissions: [],
    icon: <Icon fontSize="small">info</Icon>,
    route: "",
    href: "https://finvibes.net/about/",
  },

  // Pages
  {
    type: "page",
    name: "Profile",
    key: "client/profile",
    parent: "",
    license: [],
    permissions: [],
    icon: <Icon fontSize="small">credit_score</Icon>,
    route: "/client/profile",
    component: <Profile />,
  },
  {
    type: "page",
    name: "Loan form",
    key: "client/loan-form",
    parent: "",
    license: [],
    permissions: [],
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/client/home/:formType",
    component: <LoanForm />,
  },
  {
    type: "page",
    name: "Loan form",
    key: "client/form-screen",
    parent: "",
    license: [],
    permissions: [],
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/client/loan/:formIndex",
    component: <FormScreen />,
  },
];

export const superAdminRoute = [
  ...adminRoutes,
  {
    type: "collapse",
    name: "Mobile Users",
    key: "client/mobileusers",
    parent: "",
    license: [],
    permissions: [],
    icon: <Icon fontSize="small">person</Icon>,
    route: "/client/mobileusers",
    component: <MobileUsers />,
  },
  {
    type: "collapse",
    name: "Partners",
    key: "client/partners",
    parent: "",
    license: [],
    permissions: [],
    icon: <Icon fontSize="small">people</Icon>,
    route: "/client/partners",
    component: <PartnerUsers />,
  },
  {
    type: "pages",
    name: "Lead Utils Banks",
    key: "client/lead-utils-banks",
    parent: "",
    license: [],
    permissions: [],
    icon: <Icon fontSize="small">person</Icon>,
    route: "/client/lead-utils-banks",
    component: <LeadUtilsBanks />,
  },
  {
    type: "pages",
    name: "Lead Utils Codes",
    key: "client/lead-utils-codes",
    parent: "",
    license: [],
    permissions: [],
    icon: <Icon fontSize="small">person</Icon>,
    route: "/client/lead-utils-codes",
    component: <LeadUtilsCodes />,
  },
  {
    type: "pages",
    name: "Lead Utils Sales Manager",
    key: "client/lead-utils-sales-manager",
    parent: "",
    license: [],
    permissions: [],
    icon: <Icon fontSize="small">person</Icon>,
    route: "/client/lead-utils-sales-manager",
    component: <LeadUtilsSalesManager />,
  },
];

export default adminRoutes;
