/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import { useEffect, useState } from "react";

import Author from "components/Table/Author";
import { Switch } from "@mui/material";
import Constants from "utils/Constants";

export default function data(mobileUsers, handleActivateUser) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (mobileUsers) {
      const list = mobileUsers.map((item, index) => {
        const temp = {
          srNo: <Author name={index + 1} />,
          name: (
            <Author name={`${item.firstName} ${item?.isActive} ${item.lastName}`} maxContent={50} />
          ),
          email: <Author name={item.email} style={{ textTransform: "none" }} maxContent={50} />,
          partnerCode: <Author name={item?.partnerCode} />,
          status: (
            <Switch
              checked={item?.isActive}
              onChange={(e) => handleActivateUser(item?.[Constants.MONGOOSE_ID], e.target.checked)}
            />
          ),
        };
        return temp;
      });
      setRows([...list]);
    }
  }, [mobileUsers]);

  return {
    columns: [
      { Header: "No.", accessor: "srNo", width: "1%" },
      { Header: "Name", accessor: "name", width: "30%" },
      { Header: "Email", accessor: "email", width: "30%" },
      { Header: "Partner Code", accessor: "partnerCode", width: "25%" },
      { Header: "Status", accessor: "status", width: "10%" },
    ],
    rows,
  };
}
